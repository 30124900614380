import { Link, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";
import { Container, Section } from "../components/global";
import CtaFooter from "../components/sections/cta-footer";
import Footer from "../components/sections/footer";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      allContent: allMdx(
        filter: { fileAbsolutePath: { regex: "/content/content-pages/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              path
              title
              description
              date
              image
              cta
              ctaLink
              ctaBtnTxt
              blurb
            }
            excerpt
            body
          }
        }
      }
    }
  `);

  const contentArray = [];
  console.log(data);
  data.allContent.edges.forEach(({ node }) => {
    const { path, title, description } = node.frontmatter;
    contentArray.push(
      <li key={`content-list-${title}`}>
        <Link to={`/content/${path}/`}>{title}</Link>
      </li>
    );
  });

  return (
    <Layout>
      <SEO
        title="Data, data process and Excel articles"
        description="Articles about data, data proceses, Excel, and Ranger. Ranger is an Excel Add-in and plugin for Excel that provides a no-code solution for protecting, centralizing and storing Excel data by connecting it to the cloud."
      />
      <Navigation />
      <Container>
        <Section>
          <h1>Content</h1>
          <h4>Here are some articles about data and Ranger:</h4>
          <ul>{contentArray}</ul>
        </Section>
      </Container>
      <CtaFooter />
      <Footer />
    </Layout>
  );
};
